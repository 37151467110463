<template>
    <div class="courseRecords" :style="courseRecords">
        <common-nav-bar title="我的疗程记录"></common-nav-bar>
        <div class="instrumentCard" v-if='curSn'>
            <div class="cardTile cell myBound">
                <div class="cardTileLeft">ID</div>
                <div class="cardTileRight cellFlex-1 textL">{{ curSn }}</div>
            </div>
            <div class="cardBody">
                <div class="userInfo">
                    <van-cell-group inset>
                        <van-cell class="bgNo" center>
                            <template #icon>
                                <van-image
                                    :src="curSnUser.patientWxHeadimgurl || userPhoto"
                                    width="35"
                                />
                            </template>
                            <template #title>
                                <span class="userName mlr10">{{ curSnUser.patientName }}</span>
                                <van-icon
                                    :name="curSnUser.patientSex === 1 ? sexNan : sexNv"
                                    class="sex-icon"
                                />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </div>
        </div>
        <div class="reports mt10">
            <van-row class="reportsTitle textC">
                <van-col span="8">日期</van-col>
                <van-col span="8">操作</van-col>
                <van-col span="8">可用疗程/个</van-col>
            </van-row>
            <van-list>
                <van-row class="reportsList textC" v-for="(item, i) in list" :key="i">
                    <van-col span="8">{{ formatDay(item.createdAt) }}</van-col>
                    <van-col span="8">{{ scenes[item.scene] }}</van-col>
                    <van-col span="8">{{ item.latestCnt }}</van-col>
                </van-row>
            </van-list>
        </div>
        <van-popup v-model="showEquipmentsPopup" position="bottom">
            <van-picker
                show-toolbar
                :columns="equipmentColumns"
                @confirm="selectCurEquipment"
                @cancel="showEquipmentsPopup = false"
            />
        </van-popup>
    </div>
</template>

<script>
import { toast, formatDate} from '@/util';
import { getEquipmentTreatments } from '@/services/equipment_treatment';
import CommonNavBar from '@/components/common-nav-bar';

const scenes = [null, '系统赠送', '后台购买', '微信购买', '个人使用'];
export default {
    components: { CommonNavBar },
    // name: "index"
    data() {
        return {
            courseRecords: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
                backgroundAttachment: 'fixed',
            },
            scenes,
            list: [],
            curSn: '',
            showEquipmentsPopup: false,
            equipmentColumns: [],

            curSnUser: {},
            isMember: true,
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
        };
    },
    created() {
        this.curSn = localStorage.getItem('curSn');
        if (this.curSn) {
            this.curSnUser = JSON.parse(localStorage.getItem('curSnUser'));
        }
        this.getEquipmentTreatments();
    },
    methods: {
        async getEquipmentTreatments() {
            const { code, message, data } = await getEquipmentTreatments(this.curSn);
            if (!code) {
                return toast(message);
            }
            this.list = data;
        },
        formatDay(day) {
            return formatDate(day, 'yyyy/MM/dd');
        },
        selectCurEquipment(){

        }
    },
};
</script>

<style scoped>
.courseRecords {
    box-sizing: border-box;
}
.testReports .van-cell::after {
    border-color: #ccc;
}
.reportsTitle .van-cell::after {
    border: none;
}

.reportsTip {
    font-size: 10px;
    color: #a3a3a3;
}

.reports {
    padding-top: 40px;
    position: relative;
    overflow-y: auto;
    height: calc(100% - 239px);
}
.reportsTitle {
    font-size: 14px;
    color: #282a2d;
    line-height: 40px;
    background: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    box-shadow: 0 2px 5px #ccc;
    font-weight: 600;
}

.reportsTitle .van-cell {
    padding: 0;
    font-size: 14px;
    color: #282a2d;
    line-height: 40px;
}

.reportsTitle .van-cell .van-image {
    margin-left: 5px;
    position: relative;
    top: -3px;
}

.reportsList {
    font-size: 14px;
    color: #6d7278;
    line-height: 35px;
    /* background: rgba(255, 255, 255, 0.7); */
    padding: 5px 0;
    position: relative;
}

.reportsList ::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    border-bottom: 1px solid #ccc;
    display: block;
}
.reportsList:last-child {
    border-bottom: 0;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
    padding: 0 15px;
    font-size: 10px;
}

.instrumentCard {
    background: #fff;
    overflow: hidden;
    border-radius: 8px;
}
.cardTile {
    box-sizing: border-box;
    padding: 10px 16px;
    overflow: hidden;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

.cardTile::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background: #4ab8ab;
    width: 90%;
    left: 5%;
    bottom: 0;
}

.myBound.cardTile::after {
    background: #bebebe;
}

.cardTileLeft {
    position: relative;
    color: #acacac;
    font-weight: bold;
}

.cardTileLeft::before {
    content: '';
    display: block;
    position: absolute;
    left: -16px;
    top: 3px;
    width: 5px;
    height: 20px;
    background: #4ab8ab;
}

.cardTileRight {
    font-size: 14px;
    font-weight: 600;
    color: #282a2d;
    text-align: left;
    padding-left: 10px;
}

.cardBody .cardBodyLeft {
    font-size: 24px;
    color: #8d8d8d;
    font-weight: 600;
}

.cardBody .cardBodyRight {
    font-size: 24px;
    color: #282a2d;
    text-align: center;
}

.userInfo {
    padding: 10px;
}
.userInfo .van-cell__left-icon {
    font-size: 35px;
    height: auto;
    line-height: 100%;
}
.userName {
    font-size: 16px;
    color: #282a2d;
}
.sex-icon {
    font-size: 16px;
    vertical-align: middle;
}

.userInfo .van-cell-group {
    background: #f7f7f7;
    margin: 0;
}

.mt10 {
    margin-top: 0;
}
</style>
